@import '../../../assets/style/variables';
@import '../../../assets/style/typography';
@import '../../../assets/style/utils';

.c-time-since-start.-placeholder {
    @extend .loader;
    margin-top: .4rem;
    margin-bottom: .4rem;
    height: 1.4rem;
    width: 7rem;
}

.c-time-to-finish .-placeholder {
    @extend .loader;
    margin-top: .275rem;
    height: .7rem;
    width: 100%;
    display: inline-block;
}