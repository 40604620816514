@import '../../assets/style/variables';

.c-offline-placeholder:after {
    content:"\A";
    width: .5rem;
    height: .5rem;
    border-radius:50%;
    background: $color-offline;
    display:inline-block;
    margin-left: .4rem;
}