@import '../../../assets/style/breakpoints';
@import '../../../assets/style/utils';

.c-night-tile-layout {
    .c-tiles {
        @include desktop() { 
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                "coun dail dail"
                "maps dail dail"
                "rank dail dail";
        }
    
        @include tablet() { 
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                "dail dail dail"
                "maps maps coun"
                "maps maps rank";
        }
    
        @include phone() { 
            grid-template-columns: 1fr;
            grid-template-areas:
                "coun"
                "dail"
                "rank"
                "maps";
        }
        
        & .-countdown { 
            @extend .c-tile;
            grid-area: coun; 
        }
    }
}
