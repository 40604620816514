@import '../../assets/style/utils';
@import '../../assets/style/variables';

.c-countdown {
    @extend .row, .mt05;

    & > div {
        @extend .col;
        width: 4rem;
    }

    &__number {
        font-family: $font-family-accent;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.5rem;

        &.-placeholder {
            height: 2.5rem;
            width: 3rem;
            margin: .5rem 0;

            &.-dark {
                background-color: $color-page-background;
            }
        }
    }

    &__unit {
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
    }
}    

