@import './variables';
@import './utils';

.c-base-text {
    font-family: $font-family-primary;
    font-weight: $font-weight-light;
    
    letter-spacing: 0.05rem;

    &.-placeholder {
        @extend .loader;
        
        height: 1rem;
    }
}

.c-tile-title {
    font-family: $font-family-primary;
    font-weight: $font-weight-semibold;
    font-size: $font-size-m;
    text-transform: uppercase;
    letter-spacing: .075rem;

    color: $color-tile-secondary;
}

.c-tile-timestamp {
    font-family: $font-family-primary;
    font-size: $font-size-s;
    font-weight: $font-weight-regular;

    color: $color-tile-secondary;
}

.c-tile-value {
    font-family: $font-family-primary;
    font-size: $font-size-l;
    font-weight: $font-weight-semibold;

    color: $color-tile-primary;

    &.-placeholder {
        @extend .loader;
        margin-top: .4rem;
        margin-bottom: .4rem;
        height: 1.4rem;
        width: 100%;
    }
}

.-small {
    font-size: $font-size-xs;
}

a {
    text-decoration: none;

    & span.-underline-on-hover {
        text-decoration: underline;
    }

    &:hover span.-underline-on-hover {
        color: #34a810;
        text-decoration: underline;
        text-decoration-color: #34a810;
        text-decoration-thickness: 4px;
    }
}