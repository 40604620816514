@import '../../assets/style/variables';
@import '../../assets/style/utils';

.c-tile-timestamp:first-letter {
    text-transform: uppercase;
}

.-updated:after {
    content:"\A";
    width: .5rem;
    height: .5rem;
    border-radius:50%;
    background: $color-accent;
    display:inline-block;
    margin-left: .4rem;
}

.c-timestamp-placeholder {
    @extend .loader;
    margin-top: .375rem;
    height: .875rem;
    width: 5.5rem;
}