@import '../../../assets/style/variables';

.dail {
    background-color: $color-tile-primary !important;
    height: 100%;
    display: flex;
    flex-direction: column;

    .-cta {
        width: 100%;
        padding: .8rem 2rem .5rem 1.5rem;
        text-align: right;
        color: $color-title-text;
        font-size: $font-size-xs;
    }

    iframe {
        flex-grow: 100;
    }
}