@import '../../../assets/style/utils';
@import '../../../assets/style/typography';

.-distance {
    .-total {
        @extend .loader;
        height: 0.7rem;
        width: 3rem;
        margin-top: 0;
        display: inline-block;
    }
}