
@import '../../../assets/style/utils';

.-driver {
    .-headshot-container {
        position: absolute;
        right: 0;
        bottom: 0;
        min-width: 60%;
        height: 100%;
        padding: 1rem 1rem 0 0;
        pointer-events: none;
        
        & .-headshot {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 0 0;
        }
    }
    
    .-cta {
        @extend .mt1;
        .c-base-text.-placeholder {
            width: 8.5rem;
        }
    }
    
    .c-tile-value {
        text-transform: capitalize;

        &.-placeholder {
            width: 7rem;
        }
    }
}