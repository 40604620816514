@import '../../../assets/style/utils';
@import '../../../assets/style/breakpoints';

.c-finished-tile-layout {
    @extend .col;

    p {
        text-align: center;
    }

    .c-tiles {
        margin-top: 3rem;

        @include desktop() { 
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                "rank dail dail";
        }
    
        @include tablet() { 
            width: 100%;
            grid-template-columns: 1fr;
            grid-template-areas:
                "dail"
                "rank";
        }
    
        @include phone() { 
            grid-template-columns: 1fr;
            grid-template-areas:
                "dail"
                "rank";
        }
    }
}