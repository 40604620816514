@import '../../../assets/style/variables';

.-map {
    .leaflet-control-attribution {
        display: none;
    }

    .leaflet-control-zoom-in, .leaflet-control-zoom-out {
        border-radius: 0;
    }

    .leaflet-control {
        margin: 0;
    }

    .leaflet-top, .leaflet-left, .leaflet-bottom, .leaflet-right {
        margin: 1rem;
    }

    .leaflet-marker-icon {
        border-radius: 50%;
        border: 12px solid rgba(52, 168, 16, 0.3);
    }

    .c-timestamp {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-color: $color-tile-background;
        padding: .2rem .5rem;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        pointer-events: auto;
    }

    .c-attribution {
        pointer-events: auto;
        text-align: right;
    }
} 