@import '../../../assets/style/utils';
@import '../../../assets/style/breakpoints';

.c-driving-tile-layout {
    @extend .col;

    .c-tiles {
        @include desktop() { 
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-areas:
                "maps maps maps maps time loop dist"
                "maps maps maps maps rank rank sola"
                "maps maps maps maps rank rank temp"
                "maps maps maps maps wind driv driv"
                "maps maps maps maps alti driv driv"
                "twit twit twit dail dail dail dail";
        }
    
        @include tablet() { 
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas:
                "maps maps maps time"
                "maps maps maps loop"
                "maps maps maps dist"
                "maps maps maps sola"
                "maps maps maps temp"
                "wind alti rank rank"
                "driv driv rank rank"
                "dail dail dail dail"
                "twit twit twit twit";
        }
    
        @include phone() { 
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "maps maps"
                "time time"
                "loop dist"
                "rank rank"
                "sola temp"
                "wind alti"
                "driv driv"
                "dail dail"
                "twit twit";
        }
    }

    .c-disclaimer {
        margin-bottom: 1rem;
        opacity: 50%;
        text-align: center;
    }
}