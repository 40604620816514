@import '../../assets/style/variables';
@import '../../assets/style/utils';
@import '../../assets/style/breakpoints';

$header-shadow: 0 0 10px $color-page-background;

.c-header {
    @extend .row;
    justify-content: space-between;
    text-shadow: $header-shadow;

    @include phone() {
        flex-direction: column;
    }

    &__left-block {
        @include phone() {
            width: 100%;
        }
    }

    &__backlink a {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: $font-weight-regular;
        letter-spacing: .075rem;
    }

    &__page-title {
        font-family: $font-family-accent;
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 3.5rem;
        pointer-events: none;

        @include phone() {
            font-size: 2rem;
            line-height: 2.3rem;
        }
    }

    &__date-location {
        font-family: $font-family-accent;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.5rem;

        @include phone() {
            font-size: 1rem;
        }
    }

    &__logo {
        @extend .col;
        align-items: flex-end;
        text-transform: uppercase;
        font-weight: $font-weight-regular;
        letter-spacing: .075rem;

        @include phone() {
            flex-direction: row;
            margin-top: 1rem;
            font-size: $font-size-xs;

            img {
                margin-left: .5rem;
                height: $font-size-xs;
            }
        }

        img {
            @extend .mt05;
            filter: drop-shadow($header-shadow);
        }
    }
}