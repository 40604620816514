@import 'assets/style/variables';
@import 'assets/style/breakpoints';
@import 'assets/style/utils';


.c-dashboard {
    margin-top: 5vw;
    
    .c-tiles {  
        display: grid;
        gap: 1rem 1rem;
        grid-auto-flow: row;
        width: 100%;
    
        & .-map { 
            @extend .c-tile;
            padding: 0;
            grid-area: maps; 
            height: 100%; 
            min-height: 20rem;
    
            @include phone() {
                height: 30rem;
            }
        }
    
        & .-altitude { 
            @extend .c-tile;
            grid-area: alti; 
        }
        
        & .-temp {  
            @extend .c-tile;
            grid-area: temp; 
        }
        
        & .-solar {  
            @extend .c-tile;
            grid-area: sola; 
        }
        
        & .-wind {  
            @extend .c-tile;
            grid-area: wind; 
        }
        
        & .-driver {  
            @extend .c-tile;
            grid-area: driv; 
        }
        
        & .-ranking {  
            @extend .c-tile;
            grid-area: rank; 
        }
        
        & .-time {  
            @extend .c-tile;
            grid-area: time; 
        }
        
        & .-loops {  
            @extend .c-tile;
            grid-area: loop; 
        }
        
        & .-distance {  
            @extend .c-tile;
            grid-area: dist; 
        }
    
        & .twitter {
            @extend .c-tile;
            grid-area: twit;
    
            @include phone() {  
              display: none;
            }
        }
    
        & .dail {
            @extend .c-tile;
            grid-area: dail;
            padding: 0;
        
            iframe {
                border: none;
                border-radius: 3px;
                width: 100%;
                min-height: 400px;
            }
        }
    }
}