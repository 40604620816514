@import 'assets/style/variables';
@import 'assets/style/typography';
@import 'assets/style/breakpoints';

* {
  box-sizing: border-box;
  margin: 0;
} 

body {
  @extend .c-base-text;
  background: url("./assets/images/Header.jpg") no-repeat;
  background-size: 100vw;
  background-color: $color-page-background;

  color: $color-title-text;
  
  @include desktop() {  
    margin: 3rem 5rem;
  }

  @include tablet() {  
    background-size: 150vw;
    background-position: center top;
    margin: 3rem 4rem;
  }

  @include phone() {  
    background-size: 250vw;
    background-position: center top;
    margin: 2rem 2rem;
  }
}

a {
  color: inherit;
}