@import '../../../assets/style/variables';
@import '../../../assets/style/utils';

.-ranking {
    .c-ranking-list {
        padding-left: 0;
        counter-reset: item;
        list-style-type: none;
        
        td {
           padding-right: .5rem;
           vertical-align: top;
           padding-bottom: .5rem;
    
           &:last-child {
            padding-bottom: 0;
           }
        }
    
    
        &__flag {
            height: 2rem;
            width: 1.4rem;
        }
    
        &__number {
            font-family: $font-family-accent;
            font-weight: 600;
            font-size: 2.3rem;
            line-height: 2.3rem;
            margin-top: -4px;
        }
    
        &__team {
            @extend .col;
            align-items: flex-start;
    
            .-teamname {
                text-transform: uppercase;
                font-weight: $font-weight-semibold;
                margin-top: -4px;
    
                &.-placeholder {
                    @extend .loader;
                    height: 0.7rem;
                    width: 8rem;
                    margin-top: 0;
                }
            }
    
            .-car {
                color: $color-tile-secondary;
    
                &.-placeholder {
                    @extend .loader, .mt05;
                    height: 0.7rem;
                    width: 5rem;
                }
            }
        }
    } 

    .-cta {
        display: inline-block;
    }
}


