.c-social-media {
    margin-top: 1rem;

    & a {
        margin: 0 .35rem;

        & img {
            height: 2rem;
        }
    }
}