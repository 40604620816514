@import '../../../assets/style/utils';

.c-before-tile-layout {
    @extend .col;

    .c-tiles {
        grid-template-columns: 1fr;
        grid-template-areas: "maps";
        width: 100%;
        margin-top: 3rem;

        .-map {
            height: 500px;
        }
    }

    .c-social-media__title {
        margin-top: 3rem;
    }
}
