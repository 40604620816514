@import '../../../assets/style/utils';
@import '../../../assets/style/variables';

.c-offline-tile-layout {
    @extend .col;
    margin-top: 3rem;

    p {
        text-align: center;
    }
}
