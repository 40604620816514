$phone: 30em; // 480px
// $tablet: 42.5em; // 680px
$desktop: 60em; // 960px

@mixin phone(){
  @media screen and (max-width: $phone){
    @content;
  }
}
@mixin tablet(){
  @media screen and (min-width: $phone) and (max-width: $desktop){
    @content;
  }
}
@mixin desktop(){
  @media screen and (min-width: $desktop){
    @content;
  }
}

