// COLORS
$color-page-background: #110d0c;

$color-title-text: white;

$color-tile-primary: black;
$color-tile-secondary: #9292A5;
$color-tile-background: white;

$color-accent: #34a810;
$color-offline: #eb3452;

// FONTS
// family
$font-family-primary: 'Source Sans Pro', sans-serif;
$font-family-accent: 'Oswald', sans-serif;

// weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// sizes
$font-size-xs: .75rem;
$font-size-s: .9rem;
$font-size-m: 1rem;
$font-size-l: 1.4rem;