@import './variables';

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mv3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
} 

.mr05 {
    margin-right: .5rem;
}

.mb05 {
    margin-bottom: .5rem;
}

.mt05 {
    margin-top: .5rem;
}

.mt1 {
    margin-top: 1rem;
}

.c-tile {
    color: $color-tile-primary;
    background-color: $color-tile-background;
    padding: .8rem 1.5rem;
    position: relative;
    border-radius: 3px;
}

.loader {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loaderAnimation;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
}

@keyframes loaderAnimation{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}